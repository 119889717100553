import * as React from "react"
import { Link } from "gatsby"
import resolveLink from "../utils/resolveLink"
import Layout from "src/components/Layout";
import Heading from 'src/components/Heading'

// markup
const NotFoundPage = () => {
  return (
    <Layout>
    <div className="bg-white min-h-screen px-4 py-14 sm:px-6 md:grid md:place-items-center lg:px-8">

      <div className="max-w-2xl mx-auto">
        <main className="sm:flex">
          <p className="text-4xl font-extrabold text-red sm:text-5xl">404</p>
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
            <Heading size="h2" className="text-darkgrey">Not found</Heading>
              <span className="mt-1 text-base text-gray-500 block">Sorry, we couldn't find what you're looking for. If you still can't find the content you need either get in touch with us below or why not try our CX Insights Hub search?</span>
            </div>
            <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                <Link
                  to={resolveLink("/get-in-touch")}
                  prefetch="true"
                  className="cursor-pointer inline-flex justify-center items-center px-[30px] py-[14px] line-crop font-display tracking-normal text-white uppercase transition duration-300 text-[1.3rem] bg-red hover:bg-darkred"
                >
                  Get in touch
                  <i className="fas fa-chevron-right cursor-pointer ml-3 text-xs inline"></i>
                </Link>
                <Link
                  to={resolveLink("/cx-insights-hub")}
                  prefetch="true"
                  className="cursor-pointer inline-flex justify-center items-center px-[30px] py-[14px] line-crop font-display tracking-normal text-white uppercase transition duration-300 text-[1.3rem] bg-red hover:bg-darkred"
                >
                  CX Insights Hub
                  <i className="fas fa-chevron-right cursor-pointer ml-3 text-xs inline"></i>
                </Link>
            </div>
          </div>
        </main>
      </div>
    </div>
    </Layout>
  )
}

export default NotFoundPage
